import React from "react"
import { Link } from "gatsby"
import "./plans.scss"

const Plans = () => {
  return (
    <div className="section-container plans-intro">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center">Flexible Payment Plans</h3>
          <p className="text-center">
            In making our program more accessible and inclusive, we offer
            payment plans to accepted applicants who are not able to cover their
            tuition in one payment. With each plan, tuitions is paid in full
            before the end of the first year of the program. Accepted students
            get to start the program after they have paid 1/3 of their total
            fees. After this initial payment, you can subscribe to one of 3
            payment plans.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="card">
              <div className="card-header card-header__first">
                <h5>Annual plan</h5>
              </div>
              <div class="card-body">
                <p class="card-text">
                  If you choose the annual plan, this is how your tuition will
                  be broken up. You will make your initial deposit and then make
                  one more payment to cover the rest of your tuition fee. The
                  amount you pay is dependent on whether or not you have been
                  awarded a scholarship.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="card">
              <div className="card-header card-header__second">
                <h5>Biannual plan</h5>
              </div>
              <div class="card-body">
                <p class="card-text">
                  Your tuition fee will be broken up into 3 payments. After you
                  pay your initial deposit, you will make two additional
                  payments to cover the rest of your tuition.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="card">
              <div className="card-header card-header__third">
                <h5>Quarterly plan</h5>
              </div>
              <div class="card-body">
                <p class="card-text">
                  You first make your initial deposit. After that, you make one
                  payment each quarter. This payment is spread over the next 3
                  months. You are only required to make another payment when the
                  next quarter is starting.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="card">
              <div className="card-header card-header__fourth">
                <h5>Monthly plan</h5>
              </div>
              <div class="card-body">
                <p class="card-text">
                  This plan is the most flexible. After you make your initial
                  deposit, you do not need to struggle to raise a lump sum. You
                  simply make a payment each month to cover your tuition for the
                  month. Again, the amount you pay per month depends on whether
                  or not you have received scholarship.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row enroll-section">
          <Link
            to="https://app.codetrain.africa/apply"
            className="btn-custom-primary-large mx-auto"
          >
            Enroll now
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Plans
