import React from "react"
import { Meta, Title } from "react-head"
import MainLayout from "../components/MainLayout"
import Plans from "../components/payment-plan/Plans"

const PaymentPlans = () => {
  return (
    <MainLayout>
      <Title>Codetrain Africa | Payment Plans</Title>
      <Meta
        name="description"
        content="Accepted students get to start the program after they have 
        paid 1/3 of their total fees.After this initial payment, you can 
        subscribe to one of 3 payment plans."
      />
      <Plans />
    </MainLayout>
  )
}

export default PaymentPlans
